/* You can add global styles to this file, and also import other style files */

.label-color {
  color: rgb(48, 46, 46) !important;
  position: absolute;
  left: 1rem;
  top: 3%;
  transform: translateY(-50%);
  padding: 0 0.5rem;
  transition: all 0.3s ease;
  pointer-events: none;
  background: #f8f9fa;
}

.form-danger {
  border: #dc3545 !important;
}

.border-table-colum {
  border-left: solid 2px #f97459;
  border-right: solid 2px #f97459;
}

/*
_______________________________
_______________________________
 DATABLES 
_______________________________
_______________________________
*/
.color-uno {
  background-color: rgba(97, 94, 109, 0.1) !important;
}

.color-dos {
  background-color: rgba(175, 195, 41, 0.1) !important;
}

.color-tres {
  background-color: rgba(76, 38, 144, 0.1) !important;
}

.color-cuatro {
  background-color: rgba(92, 231, 37, 0.1) !important;
}

.color-cinco {
  background-color: rgba(33, 42, 162, 0.1) !important;
}

.color-seis {
  background-color: rgba(240, 187, 1, 0.1) !important;
}

.color-siete {
  background-color: rgba(95, 90, 40, 0.1) !important;
}

.color-ocho {
  background-color: rgba(252, 56, 56, 0.1) !important;
}

.color-nueve {
  background-color: rgba(171, 142, 14, 0.1) !important;
}

.color-diez {
  background-color: rgba(223, 77, 193, 0.1) !important;
}

.color-once {
  background-color: rgba(234, 34, 214, 0.1) !important;
}

.color-doce {
  background-color: rgba(5, 122, 37, 0.1) !important;
}

.color-trece {
  background-color: rgba(9, 141, 162, 0.1) !important;
}

.color-catorce {
  background-color: rgba(87, 214, 196, 0.1) !important;
}

.color-quince {
  background-color: rgba(34, 144, 132, 0.1) !important;
}

.color-diesiseis {
  background-color: rgba(242, 117, 181, 0.1) !important;
}

.color-diesisiete {
  background-color: rgba(149, 183, 246, 0.1) !important;
}

.color-diesiocho {
  background-color: rgba(20, 42, 183, 0.1) !important;
}

.color-diesinueve {
  background-color: rgba(68, 228, 92, 0.1) !important;
}

.color-veinte {
  background-color: rgba(19, 170, 144, 0.1) !important;
}

.color-veintiuno {
  background-color: rgba(104, 233, 138, 0.1) !important;
}

.color-veintidos {
  background-color: rgba(165, 219, 182, 0.1) !important;
}

.color-veintitres {
  background-color: rgba(197, 45, 218, 0.1) !important;
}

.color-veinticuatro {
  background-color: rgba(138, 149, 128, 0.1) !important;
}

.color-veinticinco {
  background-color: rgba(177, 87, 237, 0.1) !important;
}

.color-veintiseis {
  background-color: rgba(176, 152, 140, 0.1) !important;
}

.color-veientisiete {
  background-color: rgba(233, 74, 16, 0.1) !important;
}

.color-veintiocho {
  background-color: rgba(46, 237, 34, 0.1) !important;
}

.color-veintinueve {
  background-color: rgba(214, 135, 151, 0.1) !important;
}

.color-treinta {
  background-color: rgba(120, 8, 131, 0.1) !important;
}

.color-treintaiuno {
  background-color: rgba(7, 8, 9, 0.1) !important;
}

.center-text-list {
  text-align: center !important;
  font-size: 14px !important;
}

.center-text-list-header {
  text-align: center !important;
  font-size: 14px !important;
}

/* 
::::::::::::::::::
::::::::::::::::::
GESTION AWB
::::::::::::::::::
:::::::::::::::::: */

/*
 __________________________
 __________________________
  Style tablas 
  _________________________
  _________________________*/
ngx-datatable {
  border: solid #24677c42 3px;
  border-radius: 10px !important;
}

.ngx-datatable ::ng-deep .datatable-header-inner .datatable-header-cell-label,
.ngx-datatable ::ng-deep .datatable-header-inner .sort-btn {
  color: white !important;
}

.ngx-datatable ::ng-deep .datatable-header-inner {
  background-color: #0c2b34ea !important;
}

.body-card {
  height: 500px !important;
}

.list-group-item {
  cursor: pointer !important;
}

.list-group-item:hover {
  background-color: #cac1c1 !important;
}

.list-group {
  max-height: 600px !important;
  /* Ajusta según sea necesario */
  overflow-y: auto !important;
  z-index: 1000 !important;
  /* Asegúrate de que se superponga sobre otros elementos */
}

/* 
____________________________
____________________________
CONFIGURACIÓN PARA PANTALLAS PEQUEÑAS
____________________________
____________________________
 */

/* Tablas */
@media screen and (max-width: 1400px) {
  .body-card {
    height: 260px !important;
  }
}

@media screen and (max-width: 1600px) {
  .body-card {
    height: 400px !important;
  }
}

@media screen and (max-height: 600px) {
  .modal-body-travel {
    overflow-y: initial !important;
    overflow-y: scroll !important;
    height: 280px !important;
  }
}

/* 
____________________________
____________________________
Formulario load-awb 
____________________________
____________________________*/

.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.file-drop-area {
  border: 2px dashed #888585 !important;
  padding: 20px !important;
  text-align: center !important;
  cursor: pointer !important;
  margin-bottom: 11px;
  transition: background-color 0.3s, border-color 0.3s !important;
}

.file-drop-area.dragover {
  background-color: #979090 !important;
  border-color: #292424 !important;
}

.form-group {
  position: relative !important;
}

/* 
_________________________
_________________________
 css table del load-awb
 ________________________
 ________________________
 */

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
  min-width: 100% !important;
}

table tr th,
table tr td {
  border-right: 1px solid #bbb !important;
  border-bottom: 1px solid #bbb !important;
  padding: 5px !important;
}

table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #bbb !important;
}

table tr th {
  /* background: #d9080849 !important;*/
  text-align: left !important;
  border-top: solid 1px #bbb !important;
}

table tr:first-child th:first-child {
  border-top-left-radius: 6px !important;
}

table tr:first-child th:last-child {
  border-top-right-radius: 6px !important;
}

table tr:last-child td:first-child {
  border-bottom-left-radius: 6px !important;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 6px !important;
}

/* 
________________________
________________________
Fixed plugin configuration
________________________
________________________ 
*/
.fixed-plugin .dropdown .dropdown-toggle:after {
  display: none !important;
}

/* 
________________________
________________________
Loading spinner 
________________________
________________________ */

.overlay {
  position: fixed !important;
  z-index: 19000 !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh !important;
  background: rgba(136, 134, 134, 0.5) !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.spinner-grow {
  background-color: #07426b !important;
  width: 10rem !important;
  height: 10rem !important;
}

.overlay p {
  margin-top: 15px !important;
  font-size: 1.1rem !important;
  color: rgb(237, 229, 229) !important;
  background-color: #07426b !important;
  border-radius: 10px !important;
  padding: 5px !important;
}

/* 
_______________________
_______________________
Vector map
_______________________
_______________________ */

::ng-deep #vector-map {
  height: 420px !important;
  width: 100% !important;
  display: block !important;
}

/* _______________________
_______________________
Traceability style
_______________________
_______________________ */

/* Style tablas
___________ */
.Text-center {
  text-align: center !important;
}

.Text-left {
  text-align: left !important;
}

.Text-right {
  text-align: right !important;
}

.Text-center-white {
  text-align: center !important;
  color: white !important;
}

.Font-8 {
  font-size: 8px !important;
}

.Font-13 {
  font-size: 13px !important;
}

.Font-14 {
  font-size: 14px !important;
}

.Font-16 {
  font-size: 16px !important;
}

.Font-17 {
  font-size: 17px !important;
}

.Font-20 {
  font-size: 20px !important;
}

.FloatLeft {
  float: left !important;
}



.current-item-list-mawb {
  color: #e7e1a0 !important;
  font-size: 13px !important;
  font-weight: bold !important;
}

/* _______________________
_______________________
List awb
_______________________
_______________________ */

.Data-mawb {
  font-weight: bold !important;
  font-size: 15px !important;
}

.PositionData-mawb {
  width: 50% !important;
  float: left !important;
  text-align: left !important;
}

.DetailData-mawb {
  color: #e7e1a0 !important;
  font-size: 23px !important;
  font-weight: bold !important;
}

.PositionDetaileData-mawb {
  width: 50% !important;
  float: right !important;
  text-align: left !important;
}

.Modal-body-style {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.ContainerForm {
  width: 100% !important;
  height: 20px !important;
}

/* .FormStyle {
  line-height: 1.3 !important
} */

.ListGroupStyle {
  max-width: 95% !important;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
  z-index: 999 !important;
}

.ListGroupItemStyle {
  border: solid 1px rgb(180, 173, 173) !important;
  border-radius: 5px !important;
}

.ListStyle {
  float: left !important;
  background-color: rgba(7, 65, 107, 0.164) !important;
}

.lineStyle {
  line-height: 1.3 !important;
  text-align: center;
  
}

.ListGroupPositionStyle .AutocompleteDimensions {
  max-width: 95% !important;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
}

.ModalFooterStyle {
  background-color: #26708712 !important;
  padding: 20px !important;
}

.ContainerDimensionsLeft {
  width: 50% !important;
  float: left !important;
  text-align: left !important;
}

.ContainerDimensionsRight {
  width: 50% !important;
  float: right !important;
  text-align: left !important;
}

.DelateMawbStyle {
  line-height: 1.3 !important;
  font-weight: bold !important;
  text-align: center !important;
  font-size: 15px !important;
}

/* ___________________
___________________
Load AWB
___________________
___________________ */

.DropdownMenuStyle {
  float: right !important;
  margin-top: -60px !important;
  margin-right: 5px !important;
}

.DropdownMenuStyle2 {
  margin-top: -5px !important;
  height: 300px;
  overflow-y: scroll;
}

.ButtonFont {
  font-weight: bold !important;
  font-size: 17px !important;
}

.ListLoadStyle {
  margin-top: -1px !important;
  margin-bottom: 0px !important;
}

.FileDropStyle {
  text-align: center !important;
  max-width: 80% !important;
  margin-left: 10% !important;

  border-radius: 5px !important;
}

.ImageStyle {
  max-width: 180px !important;
  opacity: 0.5 !important;
  pointer-events: none !important;
}

.CardTitleOpacity {
  opacity: 0.7 !important;
}

.PStyle {
  opacity: 0.7 !important;
  font-size: 15px !important;
}

.ModalHeaderFontStyle {
  text-align: center !important;
  justify-content: center !important;
}

.LoadModalContainer {
  line-height: 1.1 !important;
  margin-top: 10px !important;
}

.ColorAlert {
  color: #dc3545 !important;
}

.ItemMawbFont {
  font-size: 13px !important;
  font-weight: bold !important;
  text-align: center !important;
}

.ButtonDisplay {
  display: none !important;
}

.ModalHeaderBackground {
  background-color: #267187 !important;
  color: white !important;
}

.FormValuesFont {
  text-align: left !important;
  margin-left: 33% !important;
}

/*
__________________ 
Style modal
__________________ */

.modal-header-style {
  background-color: #267187 !important;
  color: white !important;
  text-align: center !important;
  justify-content: center !important;
  height: 80px !important;
}

.modal-header-style2 {
  background-color: #267187 !important;
  color: white !important;
  text-align: center !important;
  justify-content: center !important;
}

.modal-header-style3 {
  background-color: #267187 !important;
  color: white !important;
  text-align: center !important;
  justify-content: center !important;
  height: 30px !important;
}

.index-modal {
  z-index: 9999 !important;
}

.modal-title-font {
  font-weight: bold !important;
}

.modal-body-font {
  font-weight: bold !important;
  font-size: 16px !important;
}

.ModalTitleDimension {
  margin-top: -10px !important;
  margin-bottom: 10px !important;
}

/* button modal
__________________ */
.button-width {
  width: 100% !important;
}

.text-button {
  font-size: 14px !important;
}

.ColorButton {
  color: #dc3545 !important;
  padding: 0px !important;
  margin: 0x !important;
}

/* ::::::::::::::::::::::::::::
::::::::::::::::::::::::::::
GESTIÓN DE VIAJES
::::::::::::::::::::::::::::
:::::::::::::::::::::::::::: */

/* ______________________________
______________________________
List Planification
______________________________
______________________________ */

.input-select-table-travel {
  padding: 5px !important;
  cursor: pointer !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.CardTravelStyle {
  width: 100% !important;
  margin: 0px !important;
}

.CardHeaderTravelStyle {
  height: 90px !important;
}

.ContainerCardDimension {
  width: 50% !important;
  float: left !important;
}

.ContainerActualizacionTiempo {
  width: 50% !important;
  float: right !important;
  text-align: right !important;
}

.FontAcciones {
  text-align: center !important;
  color: white !important;
}

.UptadeItemColor {
  color: #c2e7c0 !important;
}

.ErrorHorarioStyle {
  color: #dc3545 !important;
  margin-top: -10px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  font-weight: bold !important;
}

.ContainerAtenciónFont {
  font-weight: bold !important;
  font-size: 13px !important;
}

.FormCheckStyle {
  margin-top: -30px !important;
  margin-left: -10px !important;
}

.FormCheckInputStyle {
  border: solid black 2px !important;
}

.ContainerRecordatorioStyle {
  text-align: center !important;
  line-height: 1.3 !important;
  margin-top: 15px !important;
}

/* ______________________________
______________________________
List Truck travel
______________________________
______________________________ */
.input-select-table {
  cursor: pointer !important;
  padding: 5px !important;
  cursor: pointer !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.list-group-item {
  cursor: pointer !important;
}

.list-group-item:hover {
  background-color: #cac1c1 !important;
}

.list-group {
  max-height: 600px !important;
  /* Ajusta según sea necesario */
  overflow-y: auto !important;
  z-index: 1000 !important;
  /* Asegúrate de que se superponga sobre otros elementos */
}

.close-pionet {
  color: #ffffff !important;
  background-color: #07426b !important;
  border: none !important;
  margin-top: 2px !important;
}

.close-awb {
  color: #ffffff !important;
  background-color: #267187 !important;
  border: none !important;
  margin: 0px !important;
  padding: 0px !important;
  margin-top: -5px !important;
}

.text-pionet-select {
  font-size: 10px !important;
  text-transform: capitalize !important;
}

.custom-tooltip {
  --bs-tooltip-bg: var(--bd-violet-bg) !important;
  --bs-tooltip-color: var(--bs-white) !important;
}

.CardTruckTravel {
  float: right !important;
  margin-top: 20px !important;
  margin-right: 20px !important;
}

.CardtitletravelStyle {
  width: 50% !important;
  float: left !important;
  margin-top: -20px !important;
}

.AwbSelectedStyle {
  width: 100% !important;
  height: 80px !important;
  margin-top: 0px !important;
  background-color: #26708712 !important;
  border-radius: 10px !important;
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.ItemAwbSelected {
  background-color: #267187 !important;
  color: white !important;
  padding: 5px !important;
  border-radius: 10px !important;
  box-shadow: 10px !important;
  margin: 2px !important;
  width: 26% !important;
  float: left !important;
}

.ListGroupTravelStyle {
  max-width: 95% !important;
  margin-left: 2.5% !important;
  margin-right: 2.5% !important;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2) !important;
  height: 200px !important;
  overflow-y: auto !important;
}

.ContainerPionetSelected {
  width: 100% !important;
  height: 80px !important;
}

.PionetSelectedStyle {
  padding-top: 5px !important;
  width: 100% !important;
}

.TextAreaStyle {
  border: solid 1px rgb(110, 105, 105) !important;
  border-radius: 4px !important;
}

/* ____________________________
____________________________
Report travel
____________________________
____________________________ */
.Margin-40Margin-40 {
  margin-top: -40px !important;
}

/*
____________________________
____________________________
Estilo filtros 
____________________________
____________________________
*/

/* Estilos para el filtro abatible */
.filtros-toggle {
  background-color: #ffffff;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.filtros-toggle i {
  margin-right: 10px;
}

.filter-container {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: none;
  transition: max-height 0.3s ease-out;
  overflow: hidden;
  max-height: 0;
}

.filter-container.show {
  display: block;
  max-height: 500px; /* Ajusta este valor según sea necesario */
}

.filter-form {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.filterIn {
  flex: 1 1 200px;
}

.filterIn label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.filterIn input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.filter-actions {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.filter-actions button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.filtroSelect {
  height: 38px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

/* Estilos para los controles de la tabla */
.table-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
}

.table-controls select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

/* Estilos footer tables */

.items-pagination {
  color: rgb(238, 10, 10);
  font-weight: bold;
}

.ul-pagination-table {
  margin-top: -10px;
  justify-content: right !important;
}

.count-table {
  font-size: 15px;
}

.main-panel .main-content {
  margin-top: 20px;
}

/* no delete */
.row {
  margin: 8px !important;
  padding: 0px !important;
}
/* no delete */

/*Globales hawb historial*/

.event-creation {
  background-color: #d4edda;
  border-left: 5px solid #28a745;
}

.event-update {
  background-color: #fff3cd;
  border-left: 5px solid #ffc107;
}

.event-delete {
  background-color: #f8d7da;
  border-left: 5px solid #dc3545;
}

.event-default {
  background-color: #f1f1f1;
  border-left: 5px solid #6c757d;
}

/**Desplegable caja **/

.box {
  float: left;
  padding: 0 20px;
  max-width: 20%;
  min-width: 20%;
}

/* btntoggle*/
.toggle-btn {
  position: absolute;
  top: 29px;
  left: 11px;
  border: none;
  padding: 8px;
  cursor: pointer;
  z-index: 1030;
  background: none;
  pointer-events: auto;
}

.toggle-btn:focus {
  outline: none;
}

.toggle-btn i {
  font-size: 45px;
  color: #212529c7;
  cursor: pointer;
  pointer-events: auto;
}

/*select*/

.form-select,
.textarea {
  width: 100%;
}

.form-select {
  padding: 8px;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
  transition: border-color 0.3s ease;
}

.form-select:focus {
  border-color: #5a67d8;
}

.textarea {
  margin-bottom: 46px;
  margin-top: 14px;

  border: 1px solid #dcdfe6;
  border-radius: 5px;
  resize: vertical;
  transition: border-color 0.3s ease;
}

.textarea:focus {
  border-color: #5a67d8;
}
/*Check excel*/
.check-icon {
  color: #28a745;
  margin-left: 10px;
  font-size: 1.2rem;
  margin-right: 8px;
}

.error-icon {
  color: #dc3545;
  margin-left: 10px;
  font-size: 1.2rem;
}

/*Status master*/
.status-key {
  display: block;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
  background: #fbfbfbe3;
  height: 60%;
  border-radius: 4px 4px 0 0;
  text-align: center;
}

.status-value {
  display: block;
  background: #ffffff33;
  height: 46%;
  border-radius: 0 0 4px 4px;
  color: #1c1d52;
}

/*Inputs news*/
.custom-card {
  border: none;
  border-radius: 10px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
}
.custom-card:hover {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 20px, rgba(17, 17, 26, 0.08) 0px 8px 40px;
  transition: box-shadow 0.3s ease;
}

.gradient-input-group {
  position: relative;
  margin-bottom: 1rem;

  .gradient-input {
    border: 2px solid transparent;
    border-radius: 25px;
    height: 39px;
    width: 100%;
    color: black;
    transition: all 0.3s ease;
    background-image: linear-gradient(#f8f9fa, #f8f9fa), linear-gradient(to right, #4bb9d7, #0e6d8a);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    padding: 20px;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 15px rgba(0, 75, 141, 0.1);
  }
}

.gradient-input:focus + label,
.gradient-input:not(:placeholder-shown) + label {
  top: 0;
  left: 1rem;
  transform: translateY(-50%) scale(0.85);
  color: #004b8d;
}

input[type='date'].gradient-input {
  padding-right: 1rem;
}

.gradient-input:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.gradient-input.is-invalid {
  background-image: linear-gradient(white, white), linear-gradient(to right, #ff6b6b, #dc3545);
}

.gradient-button {
  background: linear-gradient(to right, #37a0bd, #234f5c);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 500;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.gradient-button:hover {
  background: linear-gradient(to right, #234f5c, #37a0bd);
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gradient-button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.gradient-button:disabled {
  background: linear-gradient(to right, #9e9e9e, #757575);
  cursor: not-allowed;
  transform: none;
}

.text-danger {
  font-weight: 600;
}

/*  tarjetas flotante list/awb*/

.card-item {
  display: flex;
  position: relative;
  height: 8em;
  flex-direction: column;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #f8f9fa;
  color: #333;
  transition: transform 0.3s;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.first {
  height: 6em;
  width: 9em;
  display: flex;
  text-align: center;
  margin: 2px;
  padding: 7px;
  flex-direction: column;
}
.card-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
}

.small-corner-box .card-item {
  margin: 0 5px 5px 0;
  padding: 5px;
}

/*Esquina datatabla hijas*/

.card-header-h {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.card-header-h i {
  margin-right: 3px;
  color: #5a67d8;
}

/*contenedor abatible guiashijas*/
.header-container-wrapper {
  position: fixed;
  top: 49px;
  right: 0em;
  width: 31.1em;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: transform 0.3s ease;
  z-index: 990;
}
.header-container-wrapper.collapsed {
  transform: translateX(88%);
}

.header-container {
  width: 29em;
  background-color: #f4f4f4c9;
  padding: 11px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  border-radius: 5px;
  pointer-events: none;
}

.label-bold {
  font-weight: bold;
  font-size: 0.9rem;
  color: #4a5568;
  display: block;
  margin-bottom: 5px;
}

/*Resumen master*/

.res-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.res-master-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: auto;
  max-width: 100%;
  height: 26rem;
}

.res-master-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
  color: #333;
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 8px 8px 0 0;
}

.res-master-content {
  display: flex;
  gap: 5px;
  /* padding: 11px; */
  background-color: #fff;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}
.res-master-content p {
  background-color: #07426b;
  border-radius: 7px;
  font-size: 1em;
  color: #fff;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, background-color 0.3s ease;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.res-master-content p:hover {
  background-color: #212529b8;
  transform: translateY(-3px);
}

.status-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.status-item {
  flex: 1 1 calc(19% - 12px);
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 3px;
  border-radius: 5px;
  color: white;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px dashed #07426b61;
}
.status-item:hover {
  transform: scale(1.05);
}

.nav-tabs .nav-link.active {
  background-color: #1c1d52;
  color: white;
  font-weight: bold;
}
i.fa-solid.fa-circle-left {
  font-size: 2rem;
  position: absolute;
  left: 2%;
  top: 6%;
  color: #07426bcf;
  border-radius: 16px;
  cursor: pointer;
}

.section-header span {
  font-weight: 600;
}

.detail-section {
  background-color: white;
  padding: 1rem;
}

.custom-datatable .datatable-body-cell,
.custom-datatable .datatable-header-cell {
  padding-left: 10px !important;
    text-align: center !important;
    display: flex
    ;
        align-content: center;
}

.search-datatable {
  padding: 12px;
  font-size: 12px;
  min-width: 90px;
}

/* Estilos para el input de navegación */
.navigation-input {
  border: 1px solid #ced4da;
  border-radius: 20px;
  padding: 8px 15px;
  width: 100%;
  outline: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.navigation-input:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.double-row-cell {
  height: 60px !important;
  padding: 5px !important;
}

.main-info {
  font-weight: bold;
  margin-bottom: 4px;
}

.consignee-info {
  font-size: 0.9em;
  color: #666;
}

.btn-dark{
  background-color: #000000 !important;
  color: #ffffff !important;
}

  .btn-hawb {
    font-size: 12px;
    padding: 9px;
    margin-bottom: -5px;
    padding-right: 17px;
}

* {
  scrollbar-width: none;  /* Para Firefox */
  -ms-overflow-style: none;  /* Para Internet Explorer y Edge */
}

*::-webkit-scrollbar {
  display: none;  /* Para Chrome, Safari y Opera */
}

html, body {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
}
